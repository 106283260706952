<template>
    <v-container>
        <base-material-card icon="mdi-factory" title="Emisores de facturación" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'EmisorCreate'})">Nuevo</v-btn>
                    <v-text-field v-model="pagination.search" append-icon="mdi-magnify" label="Búsqueda" single-line hide-details @keyup="pagination.page = 1; loadEmisores()"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="emisores" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.accciones="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn fab icon v-on="on" x-small @click="$router.push({name: 'EmisorEdit',params: {id: item.id}})">
                                        <v-icon>far fa-edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.page" class="my-4" :length="pagination.lastPage" @input="loadEmisores" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            headers: [
                { text: 'Nombre comercial', value: 'nombre_comercial', sortable: false },
                { text: 'Razón social', value: 'razon_social', sortable: false },
                { text: 'RFC', value: 'rfc', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            emisores: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                page: 1
            }
        }),
        mounted() {
            this.loadEmisores()
        },
        methods: {
            loadEmisores() {
                this.$http.get('/emisores/', {
                    params: this.pagination
                }).then(response => {
                    this.emisores = response.data.data
                    this.pagination.lastPage = response.data.last_page
                    this.pagination.currentPage = response.data.current_page
                })
            }
        }
    }
</script>